import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
import img1 from '../pictures/img/img1.png';
import img2 from '../pictures/img/img2.png';

import "../main.scss";

class Esprit extends Component {

  render() {
    return (
        <Grid 
            container 
            direction="column" 
            justify="center"
            alignItems="center"
            id="esprit"
            >
            <svg className="center" width="80" height="20">
                <line className="svgLine" x1="0" y1="0" x2="80" y2="0"/>
                <polygon className="svgTriangle" points="0,5 80,5 40,15"/>
            </svg> 
            <span className="title">Esprit de l'entreprise</span>
            <Grid 
                container
                direction="row"
                justify="center"
                alignItems="center"
                >
                <Grid item xs={3} className="textAndImg" style={{marginTop: "40px"}}>
                    <img className="marginRight noPhone" alt="logo" src={ img1 }/>
                </Grid>
                <Grid item xs={7} className="textAndImg textPhone">
                    <div className="logotext">
                        <div>
                            <p className="text">Une conception simplifiée pour un entretien dans les mois et années à venir des plus agréable.<br/>Le jardin ne doit pas devenir une corvée hebdomadaire, mais un espace de bien être et de détente.</p>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid 
                container
                direction="row"
                justify="center"
                alignItems="center"
                >
                <Grid item xs={10} className="textAndImg">
                    <div className="logotext">
                        <p className="text">Une écoute du client, de ses besoins et envies, afin de concevoir le jardin qui lui ressemble en terme d'ambiances, de couleurs et de dynamique de végétaux.</p>
                    </div>
                </Grid>
            </Grid>
            <Grid 
                container
                direction="row"
                justify="center"
                alignItems="center"
                >
                <Grid item xs={7} className="textAndImg textPhone">
                    <div className="logotext">
                        <div>
                            <p className="text">Il s'agit de votre jardin, c'est vous qui en profiterez pendant les années à venir. C'est pour cela que l'entreprise ROBA-PAYSAGE souhaite vous faire intervenir dans les différentes étapes de la conception et de la création, pour que vous puissiez ensuite voir évoluer votre jardin et en tirer le plus de satisfaction.</p>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={3} className="textAndImg">
                    <img className="marginLeft noPhone" alt="logo" src={ img2 }/>
                </Grid>
            </Grid>
            <Grid 
                container
                direction="row"
                justify="center"
                alignItems="center"
                >
                <Grid item xs={10} className="textAndImg">
                    <div className="logotext">
                        <p className="text">Des végétaux choisis et adaptés à la situation de votre jardin, balcon, terrasse. Ceci afin d'en limiter l'entretien et l'arrosage.</p>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
  }
}

export default Esprit