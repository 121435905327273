import React, { Component } from "react";
import Scrollspy from 'react-scrollspy';
import Burger from './Burger';

class Header extends Component {
  render() {
    return (
      <div className="header">
        <div>
          <a href='#accueil'>
            <span className="raleway headerText">Etienne ROBA - Conception de jardins</span>
          </a>
        </div>
        <div id='fullMenu'>
          <Scrollspy items={ ['accueil', 'esprit', 'parcours', 'projets', 'services', 'contact'] } offset={-300} currentClassName="is-current">
            <a className='raleway menuLink' href='#accueil'>
              <span>Accueil</span>
            </a>
            <a className='raleway menuLink' href='#esprit'>
              <span>Esprit d'Entreprise</span>
            </a>
            <a className='raleway menuLink' href='#parcours'>
              <span>Mon parcours</span>
            </a>
            <a className='raleway menuLink' href='#projets'>
              <span>Galeries photos</span>
            </a>
            {/*<a className='raleway menuLink' href='#services'>
              <span>Services possibles</span>
            </a>*/}
            <a className='raleway menuLink' href='#contact'>
              <span>Contact</span>
            </a>
          </Scrollspy>
        </div>
        <div id='burgerMenu'>
          <Burger/>
        </div>
      </div>  
    )
  }
}

export default Header
