import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
import img3 from '../pictures/img/img3.png';
import img4 from '../pictures/img/img4.png';

import "../main.scss";

class Parcours extends Component {

  render() {
    return (
        <Grid 
            container 
            direction="column" 
            justify="center"
            alignItems="center"
            id="parcours" 
            >
            <svg className="center" width="80" height="20">
                <line className="svgLine" x1="0" y1="0" x2="80" y2="0"/>
                <polygon className="svgTriangle" points="0,5 80,5 40,15"/>
            </svg> 
            <span className="title">Mon Parcours dans le paysage</span>
            <Grid 
                container
                direction="row"
                justify="center"
                alignItems="center"
                >
                <Grid item xs={3} className="textAndImg">
                    <img className="marginRight noPhone" alt="logo" src={ img3 }/>
                </Grid>
                <Grid item xs={8} className="textAndImg textPhone">
                    <div className="logotext">
                        <div>
                            <p className="text">Passionné depuis toujours par la nature et plus particulièrement par les végétaux ainsi que leurs utilisations dans les différents espaces extérieurs, j'oriente mes études vers un BTS aménagement paysager au Lycée Jules Rieffel, à Nantes.</p>
                            <p className="text">Cette formation m'a apportée des compétences précieuses en dessin, conception et en botanique, mais elle m'a surtout fait prendre conscience de l'importance du respect de la nature et des différents écosystèmes présents dans un jardin.</p>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid 
                container
                direction="row"
                justify="center"
                alignItems="center"
                >
                <Grid item xs={8} className="textAndImg textPhone">
                <div className="logotext">
                    <div>
                        <p className="text">Par la suite, l'obtention d'une licence professionnelle d'infographie paysagère m'a permis d'affiner mes différentes méthodes de conception. En parallèle de cette formation, j'ai travaillé pendant un an en tant que concepteur paysagiste dans une entreprise de paysage de la région nantaise, pour des particuliers mais aussi des promoteurs immobilier.</p>
                        <p className="text">Aujourd'hui, je souhaite renouer le contact avec une clientèle envisageant une approche différente dans la réalisation de son jardin, proche de la nature, ayant envie d'un jardin durable et respectueux de l'environnement.</p>
                    </div>
                </div>
                </Grid>
                <Grid item xs={3} className="textAndImg">
                    <img className="marginLeft noPhone" alt="logo" src={ img4 }/>
                </Grid>
            </Grid>
        </Grid>
    )
  }
}

export default Parcours