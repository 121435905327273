import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Scrollspy from 'react-scrollspy';
import Icon from '@material-ui/core/Icon';
import insta from '../pictures/insta.png';
import pinterest from '../pictures/pinterest.png';

export default function Burger() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <Icon>menu</Icon>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
         <Scrollspy items={ ['accueil', 'esprit', 'parcours', 'projets', 'services', 'contact'] } offset={-300} currentClassName="is-current">
            <a href='/#accueil'>
                <MenuItem className='raleway menuLink' onClick={handleClose} href='#accueil'>
                    <span>Accueil</span>
                </MenuItem>
            </a>
            <a href='/#esprit'>
                <MenuItem className='raleway menuLink' onClick={handleClose} href='#esprit'>
                    <span>Esprit d'Entreprise</span>
                </MenuItem>
            </a>
            <a href='/#parcours'>
                <MenuItem className='raleway menuLink' onClick={handleClose} href='#parcours'>
                    <span>Mon parcours</span>
                </MenuItem>
            </a>
            <a href='/#projets'>
                <MenuItem className='raleway menuLink' onClick={handleClose} href='#projets'>
                    <span>Galeries photos</span>
                </MenuItem>
            </a>
            <a href='/#services'>
                <MenuItem className='raleway menuLink' onClick={handleClose} href='#services'>
                    <span>Services possibles</span>
                </MenuItem>
            </a>
            <a href='/#contact'>
                <MenuItem className='raleway menuLink' onClick={handleClose} href='#contact'>
                    <span>Contact</span>
                </MenuItem>
            </a>
            <div className='burgerLogos'>
              <a  id='burgerLogo' href="https://www.pinterest.fr/etienneroba/" target="_blank">
                <img className="footerLogo" src={ pinterest } alt='pinterest_logo'/>
              </a>
              <a href="https://www.instagram.com/etienne__roba/" target="_blank">
                <img className="footerLogo" src={ insta } alt='instagram_logo'/>
              </a>
            </div>
        </Scrollspy>
      </Menu>
    </div>
  );
}
