import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
import Gallery from "react-photo-gallery";
import { photos } from "../photos";

import "../main.scss";

class Projets extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
  }

  render() {
    return (
      <div id="projets">
        <Grid 
            container 
            direction="column" 
            justify="center"
            alignItems="center"
            >
            <svg className="center" width="80" height="20">
                <line className="svgLine" x1="0" y1="0" x2="80" y2="0"/>
                <polygon className="svgTriangle" points="0,5 80,5 40,15"/>
            </svg> 
            <span className="title">Galeries Photos</span>
        </Grid>
        <Gallery photos={photos} direction={"column"}/>
      </div>
    )
  }
}

export default Projets