import React, { Component } from "react";
import insta from '../pictures/insta.png';
import pinterest from '../pictures/pinterest.png';

class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <a href="https://www.pinterest.fr/etienneroba/" target="_blank">
          <img className="footerLogo" src={ pinterest } alt='pinterest_logo'/>
        </a>
        <a href="https://www.instagram.com/etienne__roba/" target="_blank">
          <img className="footerLogo" src={ insta } alt='instagram_logo'/>
        </a>
      </div>  
    )
  }
}

export default Footer
