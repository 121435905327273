import img1 from './pictures/galeries/1.jpg'
import img2 from './pictures/galeries/2.jpg'
import img3 from './pictures/galeries/3.jpg'
import img4 from './pictures/galeries/4.jpg'
import img5 from './pictures/galeries/5.jpg'
import img6 from './pictures/galeries/6.jpg'
import img7 from './pictures/galeries/7.jpg'
import img8 from './pictures/galeries/8.jpg'
import img9 from './pictures/galeries/9.jpg'
import img10 from './pictures/galeries/10.jpg'
import img11 from './pictures/galeries/11.jpg'
import img12 from './pictures/galeries/12.jpg'

export const photos = [
    {
        src: img1,
        width: 3,
        height: 4,
        className: 'img'
    },
    {
        src: img2,
        width: 3,
        height: 4,
        className: 'img'
    },
    {
        src: img3,
        width: 4,
        height: 3,
        className: 'img'
    },
    {
        src: img4,
        width: 4,
        height: 2,
        className: 'img'
    },
    {
        src: img9,
        width: 5,
        height: 4,
        className: 'img'
    },
    {
        src: img6,
        width: 5,
        height: 4,
        className: 'img'
    },
    {
        src: img5,
        width: 4,
        height: 2,
        className: 'img'
    },
    {
        src: img12,
        width: 5,
        height: 4,
        className: 'img'
    },
    {
        src: img7,
        width: 1,
        height: 1,
        className: 'img'
    },
    {
        src: img8,
        width: 4,
        height: 3,
        className: 'img'
    },
    {
        src: img10,
        width: 5,
        height: 4,
        className: 'img'
    },
    {
        src: img11,
        width: 5,
        height: 4,
        className: 'img'
    }
];
  