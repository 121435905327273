import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
import img5 from '../pictures/img/img5.png';
import img6 from '../pictures/img/img6.png';
import img7 from '../pictures/img/img7.png';

import "../main.scss";

class Services extends Component {

  render() {
    return (
        <Grid 
            container 
            direction="column" 
            justify="center"
            alignItems="center"
            id="services" 
            >
            <svg className="center" width="80" height="20">
                <line className="svgLine" x1="0" y1="0" x2="80" y2="0"/>
                <polygon className="svgTriangle" points="0,5 80,5 40,15"/>
            </svg> 
            <span className="title">Services Possibles</span>
            <Grid 
                container
                direction="column"
                justify="center"
                alignItems="center"
                >
                <Grid item xs={9} className="textAndImg">
                    <div className="logotext">
                        <p className="center text italic">Pour répondre à vos différentes attentes, l'entreprise ROBA PAYSAGE met à votre disposition 3 types d'interventions, vous permettant de maitriser totalement votre budget, et de laisser plus de possibilités à l'aménagement de votre jardin.</p>
                    </div>
                </Grid>
            </Grid>
            <img className="marginLeft height100" alt="logo" src={ img5 }/>
            <Grid 
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                >
                <Grid item xs={1}/>
                <Grid item xs={10}>
                    <p className="text bold larger">1 - Conception graphique</p>
                </Grid>
            </Grid>
            <Grid 
                container
                direction="row"
                justify="center"
                alignItems="center"
                >
                <Grid item xs={1}/>
                <Grid item xs={10}>
                <span className="text">
                    <li>Visite du jardin ou de l'espace à aménager (1h environ), écoute de vos envies et vos souhaits</li>
                    <li>Création d'un plan ainsi que d'une vue de l'aménagement à venir.</li>
                    <li>Création d'une liste de végétaux (avec photographie) pour visualiser les plantes</li>
                    <li>Remise en main propre et explication du projet sur le site</li>                 
                </span>
                <p className="text">→ Tarif : 300 €</p>
                </Grid>
            </Grid>
            <img className="marginLeft height100" alt="logo" src={ img6 }/>
            <Grid 
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                >
                <Grid item xs={1}/>
                <Grid item xs={10}>
                    <p className="text larger"><span className="bold">2 -  Coaching jardin </span>- durée : ½ journée env.</p>
                </Grid>
            </Grid>
            <Grid 
                container
                direction="row"
                justify="center"
                alignItems="center"
                >
                <Grid item xs={1}/>
                <Grid item xs={10}>
                <span className="text">
                    <li>Visite du jardin ou de l'espace à aménager (1h environ), écoute de vos envies et vos souhaits</li>
                    <li>Départ ensemble pour une Pépinière de la région</li>
                    <li>Conseil, discussion, afin de choisir les végétaux adaptés et vous satisfaisant</li>
                    <li>Achat (par vous) des végétaux, correspondant aux possibilités de votre aménagement</li>                 
                    <li>Retour chez vous avec les végétaux et accessoires choisis (ou choix d'un jour de livraison)</li>                 
                </span>
                <p className="text">→ Tarif : 200 € (Plantation non comprise)</p>
                </Grid>
            </Grid>
            <img className="marginLeft height100" alt="logo" src={ img7 }/>
            <Grid 
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                >
                <Grid item xs={1}/>
                <Grid item xs={10}>
                    <p className="text larger"><span className="bold">3 - Aide à la plantation </span>- durée : ½ journée env.</p>
                </Grid>
            </Grid>
            <Grid 
                container
                direction="row"
                justify="center"
                alignItems="center"
                >
                <Grid item xs={1}/>
                <Grid item xs={10}>
                <span className="text">
                    <li>A la suite de l'achat en pépinière (2)</li>
                    <li>Aide à la plantation des végétaux choisis ensemble précédemment</li>
                    <li>Explication des techniques de plantation adaptées</li>
                    <li>Conseils pour les semaines à venir afin d'assurer une bonne reprise</li>                 
                    <li>Pas besoin de matériel, il est apporté par l'entreprise</li>                 
                </span>
                <p className="text">→ Tarif : 200 €</p>
                </Grid>
            </Grid>
            <Grid 
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                >
                <Grid item xs={1}/>
                <Grid item xs={10}>
                    <p className="text bold larger">4 - Entretien de jardins</p>
                </Grid>
            </Grid>
            <Grid 
                container
                direction="row"
                justify="center"
                alignItems="center"
                >
                <Grid item xs={1}/>
                <Grid item xs={10}>
                <span className="text">
                    <li>Rendez vous sur place pour réalisation du devis. Intervention à la demande ou contrat sur l'année</li>
                    <li>Tontes de pelouses (mulching ou ramassage)</li>
                    <li>Taille de haies de petites et moyennes dimensions</li>
                    <li>Désherbage de massifs</li>                 
                </span>
                <p className="text">→ Tarif : Variable suivant prestation et surface</p>
                </Grid>
            </Grid>
        </Grid>
    )
  }
}

export default Services