import React, { Component } from "react";
import ReactDOM from "react-dom";
import Header from './Components/Header'
import Footer from './Components/Footer'
import Esprit from './Components/Esprit'
import Parcours from './Components/Parcours'
import Projets from './Components/Projets'
import Services from './Components/Services'
import Contact from './Components/Contact'
import Grid from '@material-ui/core/Grid';
import logo from './pictures/logo.png';

import "./main.scss";

class App extends Component {

  render() {
    return (
      <div className="App">
        <Header/>
        <Grid  
          container
          direction="row"
          justify="center"
          alignItems="center"
          >
          <Grid id="accueil" item xs={10} className="bgImg" style={{padding:'20px'}}>
            <Grid direction='column'>
              <div className="logotext">
                <Grid container direction='row'>
                  <Grid item><img className="logo" alt="logo" src={logo}/></Grid>
                  <Grid item id='title'>
                    <h1>ROBA PAYSAGE</h1>
                    <h2>Ensemble, imaginons un jardin à votre image</h2>
                  </Grid>
                </Grid>
              </div>
              <div id='words'>
                <h2 id='word1' className='josefin'>Le penser</h2>
                <h2 id='word2' className='josefin'>Le créer</h2>
                <h2 id='word3' className='josefin'>L'entretenir</h2>
                <h2 id='word4' className='josefin'>En profiter</h2>
              </div>
              </Grid>
          </Grid>
          <Grid item xs={7} className="columnContent" style={{border: "4px solid black"}}>
              <Esprit/>
              <Parcours/>
              <Projets/>
              {/*<Services/>*/}
              <Contact/>
          </Grid>
        </Grid>
        <Footer/>
      </div>
    )
  }
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);