import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';

import "../main.scss";

class Services extends Component {

  render() {
    return (
        <Grid 
            container 
            direction="column" 
            justify="center"
            alignItems="center"
            id="contact" 
            >
            <svg className="center" width="80" height="20">
                <line className="svgLine" x1="0" y1="0" x2="80" y2="0"/>
                <polygon className="svgTriangle" points="0,5 80,5 40,15"/>
            </svg> 
            <span className="title">Contact</span>
            <Grid 
                container
                direction="column"
                justify="center"
                alignItems="center"
                >
                <Grid item xs={7} className="textAndImg">
                    <div className="logotext">
                        <p className="center text bold italic">Si vous avez un projet pour l'aménagement de votre jardin (terrasse, balcon ... ), n'hésitez pas à nous contacter,  nous nous ferons un plaisir de répondre à votre demande.</p>
                    </div>
                </Grid>              
            </Grid>
            <Grid container justify="center" alignItems="center" spacing={3}>
                <Grid item xs={1}/>
                <Grid item xs={1}>
                    <Icon>email</Icon>
                </Grid>
                <Grid item xs={3}>
                    <span className="text">Mail : </span>
                </Grid>
                <Grid item xs={4}>
                    <span className="text">robapaysage@outlook.fr</span>
                </Grid>
                <Grid item xs={3}/>
                <Grid item xs={1}/>
                <Grid item xs={1}>
                    <Icon>phone</Icon>
                </Grid>
                <Grid item xs={3}>
                    <span className="text">Tel : </span>
                </Grid>
                <Grid item xs={4}>
                    <span className="text">07 81 89 91 00</span>
                </Grid>
                <Grid item xs={3}/>
                <Grid item xs={1}/>
                <Grid item xs={1}>
                    <Icon>map</Icon>
                </Grid>
                <Grid item xs={3}>
                    <span className="text">Commune : </span>
                </Grid>
                <Grid item xs={4}>
                    <span className="text">La Chevrolière (44118)</span>
                </Grid>
                <Grid item xs={3}/>
            </Grid>
        </Grid>
    )
  }
}

export default Services